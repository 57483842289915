import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Rss = () => {
    const imageData = useStaticQuery(graphql`
        query RssImageQuery {
			image: file(absolutePath: { regex: "/rss-64.png/" }) {
				childImageSharp {
					gatsbyImageData(layout: FIXED, width:24, height:24)            
				}
			}        
        }
    `);    
    return (
        <GatsbyImage
            className="rss"
            image={imageData.image.childImageSharp.gatsbyImageData}
            alt="RSS-Feed"
        />
    )
}

export default Rss