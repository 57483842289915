import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Github = () => {
    const imageData = useStaticQuery(graphql`
        query GithubImageQuery {
			image: file(absolutePath: { regex: "/github.png/" }) {
				childImageSharp {
					gatsbyImageData(layout: FIXED, width:24, height:23)            
				}
			}        
        }
    `);    
    return (
        <GatsbyImage
            className="rss"
            image={imageData.image.childImageSharp.gatsbyImageData}
            alt="Github"
        />
    )
}

export default Github