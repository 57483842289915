import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Logo = () => {
    const logoData = useStaticQuery(graphql`
        query LogoQuery {
        logo: file(absolutePath: { regex: "/logo.jpg/" }) {
            childImageSharp {
				gatsbyImageData(layout: FIXED, width:150, height:150)            
            }
        }
        site {
            siteMetadata {
            title
            }
        }
        }
    `);
    const title = logoData.site.siteMetadata.title;
    return (
        <GatsbyImage
            className="logo"
            image={logoData.logo.childImageSharp.gatsbyImageData}
            alt={title}
        />
    )
}

export default Logo
