import React from "react"
import { Link } from "gatsby"
import Logo from "./logo"
//import Rss from "./rss"
import Github from "./github"
import Rss from "./rss"
import Youtube from "./yt"
import "./layout.css"

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div className="page-container">
      <section id="left-bar" role="navigation">
        <Link to={`/`} className="logolink">
          <Logo />
        </Link>
        <h1 className="title">codingfreaks</h1>
        <h2 className="claim">Experiencing Microsoft</h2>
        <nav role="main">
          <ul>
            <li><Link to={`/archive`}>Archive</Link></li>
            <li><Link to={`/tools`}>Tools</Link></li>
            <li><Link to={`/about`}>About</Link></li>
            <li><Link to={`/privacy`}>Privacy</Link></li>
          </ul>
        </nav>
		<div className="social">
			<ul>
				<li>
					<a href="/rss.xml" target="_blank" rel="noreferrer">
						<Rss />
					</a>
				</li>
				<li>
					<a href="https://github.com/codingfreak" target="_blank" rel="noreferrer">
						<Github />
					</a>
				</li>
				<li>
					<a href="https://www.youtube.com/@real-codingfreaks" target="_blank" rel="noreferrer">
						<Youtube />
					</a>
				</li>
			</ul>			
		</div>
      </section>
      <section id="main-area">
        <main>
          {children}
        </main>
      </section>
    </div>
    )
  }
}

export default Layout
